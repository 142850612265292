import React from "react";

export const ContactsList = ({ children, ...rest }: ComponentProps) => {
  return (
    <div className="contacts-list" {...rest}>
      {children}
    </div>
  );
};

export const ContactItem = ({
  label,
  link,
  value,
}: {
  label: string;
  link: string;
  value: string;
}) => {
  return (
    <div className="contact-item">
      <div className="contact-label">{label}</div>
      <a
        className="contact-value"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    </div>
  );
};
