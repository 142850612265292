import React from "react";

export const Logo = ({
  accentColor = "#00B67A",
  textColor = "#000000",
}: {
  accentColor?: string;
  textColor?: string;
}) => {
  return (
    <svg
      className={`logo`}
      version="1.1"
      width="895.63"
      height="248.24"
      viewBox="0 0 895.63 248.24"
    >
      <g>
        <path
          fill={textColor}
          d="M249.55,88.62L271,155.39l21.45-66.77h16.01l21.45,66.77l21.45-66.77h24.17l-34.29,100.76h-19.64l-21.15-61.18l-21.15,61.18h-19.64L225.38,88.62H249.55z"
        />
        <path fill={textColor} d="M410.88,88.62v100.76h-23.26V88.62H410.88z" />
        <path
          fill={textColor}
          d="M481.58,113.25c-7.55-6.8-14.05-7.25-16.92-7.25c-3.17,0-7.1,0.45-9.82,3.47c-1.51,1.51-2.57,3.78-2.57,6.34c0,2.42,0.76,4.23,2.27,5.59c2.42,2.27,5.89,3.17,12.69,5.74l7.55,2.87c4.38,1.66,9.82,3.93,13.75,7.7c5.89,5.59,7.4,12.84,7.4,18.73c0,10.42-3.63,19.49-8.91,24.92c-8.91,9.37-21.9,10.27-28.55,10.27c-7.25,0-13.59-1.06-19.94-4.23c-5.14-2.57-11.03-7.25-14.8-11.03l12.08-16.62c2.57,2.57,6.65,6.04,9.37,7.7c3.93,2.42,8.01,3.63,12.69,3.63c3.02,0,7.25-0.6,10.57-3.47c1.96-1.66,3.62-4.38,3.62-8.16c0-3.32-1.36-5.44-3.47-7.25c-2.72-2.27-8.91-4.53-11.78-5.59l-8.31-2.87c-4.68-1.66-10.12-3.78-14.05-8.01c-5.29-5.59-6.04-12.69-6.04-17.52c0-8.91,2.72-16.47,8.76-22.66c7.1-7.25,15.56-9.21,24.92-9.21c6.95,0,18.13,1.21,29.91,9.97L481.58,113.25z"
        />
        <path
          fill={textColor}
          d="M565.87,108.26h-33.69v19.49h32.03v19.64h-32.03v22.36h33.69v19.64h-56.95V88.62h56.95V108.26z"
        />
        <path
          fill={textColor}
          d="M598.5,88.62l21.45,66.77l21.45-66.77h16.01l21.45,66.77l21.45-66.77h24.17l-34.29,100.76h-19.64L649.4,128.2l-21.15,61.18h-19.64L574.33,88.62H598.5z"
        />
        <path
          fill={textColor}
          d="M782.94,170.35h-38.07l-7.7,19.03H713l40.64-100.76h21.3l39.73,100.76h-24.17L782.94,170.35z M776.6,152.52l-12.39-33.84l-12.54,33.84H776.6z"
        />
        <path
          fill={textColor}
          d="M836.26,142.4l-36.1-53.78h27.49l20.24,31.27l20.24-31.27h27.49l-36.1,53.78v46.98h-23.26V142.4z"
        />
      </g>
      <path
        fill={accentColor}
        d="M61.71,208.38c-5.81-5.81-11.98-11.45-17.95-16.9C21.26,170.92,0,151.51,0,125.17c0-19.15,9.22-37.47,24.66-48.99L31.59,71l46.6,46.6l23.56-23.56l23.56,23.56l46.6-46.6l6.93,5.17c15.43,11.51,24.64,29.83,24.64,48.99c0,26.32-20.95,45.44-43.12,65.68c-5.95,5.43-12.09,11.04-17.92,16.87l-14.14-14.14c6.15-6.15,12.47-11.92,18.58-17.5c19.64-17.93,36.61-33.41,36.61-50.91c0-10.19-3.67-19.73-10.28-27.17l-47.89,47.88l-23.56-23.56l-23.56,23.56L30.3,98c-6.62,7.45-10.3,16.99-10.3,27.17c0,17.52,17.26,33.29,37.25,51.54c6.13,5.6,12.47,11.39,18.61,17.53L61.71,208.38z"
      />
      <path
        fill={accentColor}
        d="M101.17,248.24c-18.79,0-36.26-7.47-49.21-21.04l-6.74-7.07l56.53-56.53l56.1,56.1l-6.96,6.96C138.06,240.37,119.95,248.24,101.17,248.24z M73.87,219.76c7.94,5.51,17.36,8.47,27.3,8.47c10.23,0,19.89-3.13,27.97-8.95l-27.4-27.4L73.87,219.76z"
      />
      <path
        fill={accentColor}
        d="M156.19,62.52c-5.92,5.92-23.38,23.16-23.38,23.16l-7.5,7.5l-23.56-23.56L78.19,93.18L47.58,62.57l-0.29-3.69c-0.11-1.41-0.16-2.84-0.16-4.27C47.12,24.5,71.63,0,101.74,0s54.62,24.5,54.62,54.62C156.36,56.04,156.3,61.1,156.19,62.52M67.13,53.84l11.05,11.05l23.56-23.56l23.56,23.56l11.04-11.04C135.94,35.12,120.58,20,101.74,20C82.91,20,67.55,35.11,67.13,53.84z"
      />
    </svg>
  );
};
