export const store: IContacts = {
  email: "hello@wiseway.lv",
  phone: "+371 29 662 694",
  address: {
    street: "Avotu",
    building: "23",
    office: "3",
    city: "Riga",
    country: "LV-1011",
  },
  copyright: {
    title: "© 2020, Wiseway.",
    text: ["Software Development Company.", "All rights reserved."],
  },
};
