import React from "react";

export const RemoveIcon = () => {
  return (
    <svg
      className={`svg-icon icon-remove`}
      width="26.83"
      height="26.83"
      viewBox="0 0 26.83 26.83"
    >
      <polygon points="26.83 2.83 24 0 13.41 10.59 2.83 0 0 2.83 10.59 13.41 0 24 2.83 26.83 13.41 16.24 24 26.83 26.83 24 16.24 13.41 26.83 2.83" />
    </svg>
  );
};
