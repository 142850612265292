import React from "react";

export const Copyright = ({ copyright }: { copyright: ICopyright }) => {
  return (
    <div className="copyright">
      <div className="copyright-title">{copyright.title}</div>
      {copyright.text.map((text, index) => (
        <div className="copyright-text" key={`copyright-text-${index}`}>
          {text}
        </div>
      ))}
    </div>
  );
};
