import React from "react";

export const VideoBackground = ({
  children,
  src,
  className,
}: {
  children?: React.ReactNode;
  src?: string;
  className?: string;
}) => {
  return (
    <div
      className={
        className ? `video-background ${className}` : `video-background`
      }
    >
      <video className="video-element" src={src} autoPlay muted loop></video>
      {children}
    </div>
  );
};
