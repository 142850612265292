import React from "react";

export const ConnectionIcon = () => {
  return (
    <svg className={`svg-icon icon-connection`} width="45px" height="36px">
      <path
        fillRule="evenodd"
        d="M44.710,10.512 C44.512,10.708 44.253,10.807 43.992,10.807 C43.734,10.807 43.475,10.710 43.277,10.515 L42.926,10.174 C42.524,9.789 42.515,9.157 42.905,8.761 C43.294,8.366 43.936,8.356 44.337,8.741 L44.707,9.100 C45.104,9.489 45.105,10.122 44.710,10.512 ZM40.370,7.751 C40.163,7.751 39.954,7.689 39.775,7.560 C39.498,7.361 39.218,7.167 38.935,6.979 C38.472,6.670 38.350,6.049 38.663,5.593 C38.975,5.136 39.606,5.014 40.069,5.324 C40.371,5.525 40.670,5.732 40.967,5.944 C41.419,6.269 41.519,6.893 41.190,7.340 C40.991,7.609 40.683,7.751 40.370,7.751 ZM36.164,5.343 C24.719,-0.588 10.881,1.491 1.730,10.514 C1.532,10.709 1.273,10.807 1.013,10.807 C0.754,10.807 0.495,10.709 0.297,10.514 C-0.099,10.124 -0.099,9.492 0.297,9.101 C10.078,-0.543 24.871,-2.765 37.107,3.575 C37.602,3.832 37.793,4.436 37.532,4.925 C37.273,5.413 36.661,5.602 36.164,5.343 ZM11.109,18.263 C11.527,17.898 12.168,17.937 12.539,18.350 C12.909,18.764 12.870,19.394 12.450,19.760 C12.215,19.965 11.984,20.180 11.758,20.402 C11.561,20.597 11.301,20.694 11.042,20.694 C10.783,20.694 10.524,20.597 10.326,20.402 C9.930,20.012 9.930,19.380 10.326,18.990 C10.581,18.738 10.842,18.495 11.109,18.263 ZM14.158,16.138 C20.837,12.488 29.276,13.661 34.680,18.990 C35.076,19.380 35.076,20.012 34.680,20.402 C34.482,20.597 34.223,20.694 33.964,20.694 C33.705,20.694 33.445,20.597 33.247,20.402 C28.478,15.699 21.031,14.663 15.140,17.885 C14.650,18.155 14.035,17.978 13.763,17.496 C13.492,17.014 13.669,16.405 14.158,16.138 ZM22.503,27.999 C24.737,27.999 26.555,29.792 26.555,31.995 C26.555,34.198 24.737,35.990 22.503,35.990 C20.269,35.990 18.451,34.198 18.451,31.995 C18.451,29.792 20.269,27.999 22.503,27.999 ZM22.503,33.992 C23.620,33.992 24.529,33.096 24.529,31.995 C24.529,30.893 23.620,29.997 22.503,29.997 C21.385,29.997 20.477,30.893 20.477,31.995 C20.477,33.096 21.385,33.992 22.503,33.992 Z"
      />
    </svg>
  );
};
