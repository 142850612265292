import React from "react";
import { Container } from "../components/Container";
import { Icon } from "../components/Icon";

const TecnologyItem = ({ icon, name }: { icon: string; name: string }) => (
  <span className="tecnology-item">
    {<Icon name={icon} />} {name}
  </span>
);

export const TechnologySection = ({ ...props }: ComponentProps) => {
  return (
    <section className="technology" {...props}>
      <Container>
        <h2 className="technology-title">Technology & Team</h2>

        <div className="technology-content">
          <div className="content-intro">
            Along with a deep understanding of all basic technologies{" "}
            <TecnologyItem icon="angular" name="Angular" />,{" "}
            <TecnologyItem icon="gitlab" name="Gitlab" />,{" "}
            <TecnologyItem icon="rails" name="Rails" />,{" "}
            <TecnologyItem icon="react" name="React" />,{" "}
            <TecnologyItem icon="python" name="Python" />, we strive to use the
            most modern & high-tech tools{" "}
            <TecnologyItem icon="node-js" name="NodeJS" />,{" "}
            <TecnologyItem icon="postgre-sql" name="PostgreSQL" />,{" "}
            <TecnologyItem icon="unity" name="Unity" /> to implement our
            projects.
          </div>
          <div className="content-text">
            Experienced strong leaders, modern art directors & designers,
            advanced team leads & managers, hardcore programmers. Many of us
            have 10-15 years. Our eyes are on fire, every result achieved makes
            us set even higher goals.
          </div>
        </div>
      </Container>
    </section>
  );
};
