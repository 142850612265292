import React, { useCallback, useState, useRef, useEffect } from "react";

type InputProps = {
  value?: string;
  label?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => any;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const Input = ({
  type,
  name,
  label,
  value,
  className,
  onChange,
  onInput,
  required,
}: InputProps) => {
  const [inputValue, setValue] = useState(value || "");
  const [active, setActive] = useState(inputValue.length > 0);
  const [height, setHeight] = useState(44);
  const [validate, setValidate] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const classList: string[] = ["input"];

  if (className) {
    classList.push(className);
  }

  if (type) {
    classList.push(`is-${type}`);
  }

  if (active) {
    classList.push("is-active");
  }

  if (validate) {
    classList.push("is-validating");
  }

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(event);
      }

      setValue(event.target.value);
    },
    [onChange],
  );

  const onFocusHandler = useCallback(() => {
    setActive(true);
  }, []);

  const onBlurHandler = useCallback(() => {
    setActive(inputValue.length > 0);
  }, [inputValue]);

  const onInputHandler = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onInput) {
        onInput(event as React.FormEvent<HTMLInputElement>);
      }

      if (inputValue.length > 0 && validate === false) {
        setValidate(true);
      }
    },
    [inputValue, validate, onInput],
  );

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;

      if (textarea.scrollHeight > textarea.clientHeight) {
        const style = getComputedStyle(textarea);

        setHeight(
          parseInt(style.paddingBottom, 10) +
            parseInt(style.paddingTop, 10) +
            textarea.scrollHeight,
        );
      }
    }
  }, [inputValue]);

  return (
    <div className={classList.join(" ")}>
      {label ? <div className="input-label">{label}</div> : null}

      {type === "textarea" ? (
        <textarea
          ref={textareaRef}
          name={name}
          value={inputValue}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onInput={onInputHandler}
          style={{ height: height + "px" }}
          required={required}
        />
      ) : (
        <input
          type={type}
          name={name}
          value={inputValue}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onInput={onInputHandler}
          required={required}
        />
      )}
    </div>
  );
};
