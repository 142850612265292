import React from "react";
import { Icon } from "./Icon";

export const Address = ({ address }: { address: IAddress }) => {
  return (
    <address className="address">
      <div className="address-line1">
        <Icon name="location" /> {address.street}, {address.building},{" "}
        {address.office},
      </div>
      <div className="address-line2">
        {address.city}, {address.country}.
      </div>
    </address>
  );
};
