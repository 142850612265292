import React, { useCallback } from "react";
import { Icon } from "./Icon";
import { RetryButton } from "./RetryButton";

export const FormResponse = ({
  title,
  retryText,
  icon,
  status,
  className,
  onAccept,
  children,
  ...props
}: {
  icon: string;
  title: string;
  retryText: string;
  status: IFormStatus;
  onAccept?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => any;
} & ComponentProps) => {
  const classList: string[] = [`form-response`, `response-${status}`];

  if (className) {
    classList.push(className);
  }

  const onAcceptHandler = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onAccept) {
        onAccept(event);
      }
    },
    [onAccept],
  );

  return (
    <div className={classList.join(" ")} {...props}>
      <div className="response-header">
        <Icon name={icon} />
        <div className="response-title">{title}</div>
      </div>
      <div className="response-body">{children}</div>
      <RetryButton onClick={onAcceptHandler}>{retryText}</RetryButton>
    </div>
  );
};
