import { useState, useEffect } from "react";

export const useFetch = <T>(url: string): T | null => {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(url);
      const json = await response.json();
      setData(json);
    }

    fetchData();
  }, [url]);

  return data;
};
