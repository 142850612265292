import React, { useCallback } from "react";
import { Logo } from "../components/Logo";
import { Nav, NavItem } from "../components/Nav";
import { Container } from "../components/Container";
import { ContactsList, ContactItem } from "../components/ContactsList";
import { store } from "../store";
import { Address } from "../components/Address";
import { Copyright } from "../components/Copyright";

export const Footer = () => {
  const onNavClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
  }, []);

  return (
    <footer className="footer">
      <Container>
        <div className="nav-container">
          <Logo textColor="#FFFFFF" />
          <Nav>
            <NavItem link="#solutions" title="Solutions" onClick={onNavClick} />
            <NavItem link="#expertise" title="Expertise" onClick={onNavClick} />
            <NavItem
              link="#technology"
              title="Technology"
              onClick={onNavClick}
            />
            <NavItem link="#contacts" title="Contacts" onClick={onNavClick} />
          </Nav>
        </div>
        <ContactsList>
          <Address address={store.address} />
          <ContactItem
            label="Call the number"
            link={`tel:${store.phone}`}
            value={store.phone}
          />
          <ContactItem
            label="Send E-mail to"
            link={`mailto:${store.email}`}
            value={store.email}
          />
          <Copyright copyright={store.copyright} />
        </ContactsList>
      </Container>
    </footer>
  );
};
