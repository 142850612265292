import React from "react";

interface ExpertiseItemProps {
  icon?: React.ReactNode;
  title?: string;
  text?: string;
}

export const ExpertiseItem = ({ icon, title, text }: ExpertiseItemProps) => {
  return (
    <div className="expertise-item">
      <div className="item-header">
        <div className="item-line"></div>
        {icon}
      </div>
      <div className="item-title">{title}</div>
      <div className="item-text">{text}</div>
    </div>
  );
};
