import React, { useState, useCallback } from "react";
import { Container } from "../components/Container";
import { Input } from "../components/Input";
import { ConfirmButton } from "../components/ConfirmButton";
import { Form } from "../components/Form";
import { FormResponse } from "../components/FormResponse";
import { store } from "../store";

export const ContactsSection = ({ ...props }: ComponentProps) => {
  const [status, setStatus] = useState<IFormStatus>("none");
  const formAction =
    process.env.NODE_ENV === "development"
      ? `http://127.0.0.1:${process.env.REACT_APP_PHP_PORT}/send-email.php`
      : `send-email.php`;

  const onStatusUpdateHandler = useCallback((status: IFormStatus) => {
    setStatus(status);
  }, []);

  const onAcceptHandler = useCallback(() => {
    setStatus("none");
  }, []);

  return (
    <section className="contacts" {...props}>
      <Container>
        <Form
          className="contact-form"
          action={formAction}
          onStatusUpdate={onStatusUpdateHandler}
        >
          {status === "success" ? (
            <FormResponse
              status={status}
              title="Sent successful"
              retryText="Send another one"
              icon="check"
              onAccept={onAcceptHandler}
            >
              Thank you for contacting us. We will contact you as soon as
              posible.
            </FormResponse>
          ) : status === "error" ? (
            <FormResponse
              status={status}
              title="Sending error"
              retryText="Try again"
              icon="remove"
              onAccept={onAcceptHandler}
            >
              Oh, something went wrong. Try again later, of write to{" "}
              <a href={`mailto:${store.email}`}>{store.email}</a>
            </FormResponse>
          ) : null}

          <h2 className="form-title">Get in touch</h2>
          <Input
            type="text"
            name="name"
            label="Name"
            value=""
            pattern="[a-z]{4,8}"
            required={true}
          />
          <Input type="email" name="email" label="Email" required={true} />
          <Input type="textarea" name="message" label="Message" value="" />
          <ConfirmButton>Send</ConfirmButton>
        </Form>
      </Container>
    </section>
  );
};
