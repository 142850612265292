import React from "react";

export const ColoredHandIcon = () => {
  return (
    <svg className={`svg-icon icon-hand-colored`} width="24" height="24">
      <path
        d="M23.89,15.56a6.44,6.44,0,0,1-1.67,5.68l-.73.75a6.48,6.48,0,0,1-9.25.14s0,0,0,0L3.14,12.73a1.26,1.26,0,0,1,0-1.75,1.17,1.17,0,0,1,1.69,0l5.35,5.53A7.27,7.27,0,0,1,10.5,15l-6.81-7a1.26,1.26,0,0,1,0-1.75,1.17,1.17,0,0,1,1.7,0l6.33,6.54a5.2,5.2,0,0,1,.38-.44l.5-.52L4.92,3.83a1.26,1.26,0,0,1,0-1.75,1.17,1.17,0,0,1,1.69,0l7.94,8.21a6.14,6.14,0,0,1,1.37-.46l-7-7.27a1.28,1.28,0,0,1,0-1.75h0a1.17,1.17,0,0,1,1.69,0l9.24,9.54.05,0a.54.54,0,0,0,.77-.56C20.48,8.37,20,8.06,20,6.39,20,3.71,21.64,2,22.61,3s.65,3.31.65,4C23.26,10.55,24,15.4,23.89,15.56Z"
        fill="#ffc000"
        fillRule="evenodd"
      />
      <path
        d="M14.35,2.25A5.29,5.29,0,0,1,17,5a.22.22,0,0,1-.13.3l-.08,0a.24.24,0,0,1-.21-.15,4.84,4.84,0,0,0-2.4-2.48.25.25,0,0,1-.13-.3A.23.23,0,0,1,14.35,2.25Z"
        fill="#191b1d"
        fillRule="evenodd"
      />
      <path
        d="M19.14,5.49l-.09,0a.27.27,0,0,1-.24-.17A9.48,9.48,0,0,0,14.13.5.28.28,0,0,1,14,.16.25.25,0,0,1,14.31,0a10,10,0,0,1,5,5.14A.26.26,0,0,1,19.14,5.49Z"
        fill="#191b1d"
        fillRule="evenodd"
      />
      <path
        d="M5.08,16.54a.26.26,0,0,1,.14.33A.25.25,0,0,1,5,17H4.9a5.9,5.9,0,0,1-3-3.06.27.27,0,0,1,.14-.34.25.25,0,0,1,.32.15A5.44,5.44,0,0,0,5.08,16.54Z"
        fill="#191b1d"
        fillRule="evenodd"
      />
      <path
        d="M5.16,18.43a.26.26,0,0,1,.14.33.25.25,0,0,1-.23.16H5a10.09,10.09,0,0,1-5-5.14.27.27,0,0,1,.14-.34.25.25,0,0,1,.32.15A9.52,9.52,0,0,0,5.16,18.43Z"
        fill="#191b1d"
        fillRule="evenodd"
      />
    </svg>
  );
};
