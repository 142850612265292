import React from "react";

export const CheckIcon = () => {
  return (
    <svg
      className={`svg-icon icon-check`}
      width="30.83"
      height="23.24"
      viewBox="0 0 30.83 23.24"
    >
      <polygon points="10.41 23.24 0 12.83 2.83 10 10.41 17.59 28 0 30.83 2.83 10.41 23.24" />
    </svg>
  );
};
