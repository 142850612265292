import React from "react";
import { Container } from "../components/Container";
import { Icon } from "../components/Icon";

const SolutionItem = ({ children }: ComponentProps) => {
  return (
    <div className="solution-item">
      <div className="item-content">{children}</div>
    </div>
  );
};

export const SolutionsSection = ({ ...props }: ComponentProps) => {
  return (
    <section className="solutions" {...props}>
      <Container>
        <h2 className="solutions-title">We Work For</h2>
        <div className="solutions-text">
          We develop software solutions for{" "}
          <span className="text-accent">fast-growing industries</span>. And
          competent analytics & initial design allow you to do this most
          effectively.
        </div>

        <div className="solutions-list">
          <SolutionItem>
            <Icon name="wallet" />
            <div className="item-text">Payment & Financial</div>
            <div className="item-text">Systems</div>
          </SolutionItem>
          <SolutionItem>
            <Icon name="house" />
            <div className="item-text">Real Estate</div>
            <div className="item-text">& Construction</div>
          </SolutionItem>
          <SolutionItem>
            <Icon name="commerce" />
            <div className="item-text">eCommerce</div>
            <div className="item-text">Projects</div>
          </SolutionItem>
          <SolutionItem>
            <Icon name="gear" />
            <div className="item-text">Industry</div>
            <div className="item-text">& Education</div>
          </SolutionItem>
          <SolutionItem>
            <Icon name="connection" />
            <div className="item-text">Telecomunications</div>
            <div className="item-text">Fields</div>
          </SolutionItem>
          <SolutionItem>
            <Icon name="heart" />
            <div className="item-text">Health & Beauty</div>
          </SolutionItem>
        </div>
      </Container>
    </section>
  );
};
