export const scrollToElement = (
  element: Element | string,
  offset: number = 0,
) => {
  const target =
    typeof element === "string" ? document.querySelector(element) : element;

  if (target) {
    const { top } = target.getBoundingClientRect();

    window.scrollTo({
      top: top + window.pageYOffset - offset,
      behavior: "smooth",
    });
  }
};
