import React, { useCallback, useEffect, useRef, useState } from "react";

const sendForm = async (
  url: string,
  formData: FormData,
): Promise<IFormStatus> => {
  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    const json: {
      status: "success" | "error";
    } = await response.json();

    return json.status;
  } catch {
    return "error";
  }
};

export const Form = ({
  children,
  onSubmit,
  onStatusUpdate,
  action,
  ...props
}: {
  action?: string;
  onStatusUpdate?: (status: IFormStatus) => any;
} & ComponentProps) => {
  const [status, setStatus] = useState<IFormStatus>("none");
  const ref = useRef<HTMLFormElement>(null);
  const onSubmitHandler = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      if (onSubmit) {
        onSubmit(event);
      }

      setStatus("none");

      if (action) {
        event.preventDefault();

        if (ref.current) {
          const form = ref.current;
          const formData = new FormData(form);
          setStatus(await sendForm(action, formData));
        }
      }
    },
    [onSubmit, action, ref],
  );

  useEffect(() => {
    if (onStatusUpdate) {
      onStatusUpdate(status);
    }
  }, [status, onStatusUpdate]);

  return (
    <form ref={ref} onSubmit={onSubmitHandler} {...props}>
      {children}
    </form>
  );
};
