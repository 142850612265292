import React, { useEffect, useState, useRef } from "react";
import { useFetch } from "../hooks/useFetch";

interface IMapConfig {
  markers: any[];
  config: any;
  key: string;
}

const useMapConfig = (url: string): [IMapConfig | null, boolean] => {
  const [ready, setReady] = useState(false);
  const mapConfig = useFetch<IMapConfig>(url);

  useEffect(() => {
    if (ready === false) {
      if (process.env.NODE_ENV === "development") {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
        document.body.appendChild(script);
      }

      const interval = setInterval(() => {
        if (window.google) {
          setReady(true);
          clearInterval(interval);
        }
      }, 50);
    }
  }, [ready]);

  return [mapConfig, ready];
};

export const MapSection = () => {
  const [mapConfig, ready] = useMapConfig(`/static/data/map-config.json`);
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ready && mapConfig && ref.current) {
      const container = ref.current;

      const map = new google.maps.Map(container, {
        zoom: mapConfig.config.zoom,
        center: mapConfig.config.center,
        styles: mapConfig.config.styles,
      });

      mapConfig.markers.forEach(({ position }) => {
        new google.maps.Marker({ position: position, map: map });
      });
    }
  }, [mapConfig, ready]);

  return <section ref={ref} className="map"></section>;
};
