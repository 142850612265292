import React from "react";

export const ArrowIcon = () => {
  return (
    <svg className={`svg-icon icon-arrow`} width="8" height="12">
      <path
        fillRule="evenodd"
        fill="rgb(25, 27, 29)"
        d="M7.667,8.393 C8.062,8.060 8.107,7.474 7.769,7.085 L7.769,7.084 C7.432,6.695 6.838,6.650 6.444,6.984 L4.947,8.747 L4.947,0.915 C4.947,0.402 4.526,-0.014 4.007,-0.014 C3.488,-0.014 3.067,0.402 3.067,0.915 L3.067,8.747 L1.571,6.984 C1.177,6.650 0.583,6.695 0.245,7.084 L0.245,7.085 C-0.093,7.474 -0.047,8.060 0.347,8.393 L4.007,11.986 L7.667,8.393 Z"
      />
    </svg>
  );
};
