import React from "react";

export const MarkerIcon = (props: any) => {
  return (
    <svg
      className={`svg-icon icon-marker`}
      width="20"
      height="34.9"
      viewBox="0 0 20 34.9"
      {...props}
    >
      <g>
        <path
          d="M3,3.7A9.06,9.06,0,0,0,.59,9.8C.67,14.35,2.72,16.08,6,22.35A59.53,59.53,0,0,1,9.6,32.87a8.27,8.27,0,0,0,.4,1.44A4.26,4.26,0,0,0,10.4,33,59.67,59.67,0,0,1,14,22.47c3.29-6.28,5.34-8,5.42-12.56A9,9,0,0,0,17,3.82,10.14,10.14,0,0,0,10,.59,9.64,9.64,0,0,0,3,3.7Z"
          fill="#ff4646"
          stroke="#d73534"
          strokeMiterlimit="4.74"
          strokeWidth="1.19"
        />
        <circle cx="10" cy="9.96" r="3.6" fill="#590000" />
      </g>
    </svg>
  );
};
