import React from "react";

export const LocationIcon = () => {
  return (
    <svg className={`svg-icon icon-location`} width="7" height="9">
      <path
        fillRule="evenodd"
        d="M3.500,8.999 C3.500,8.999 -0.001,5.644 -0.001,3.635 C-0.001,1.627 1.567,-0.001 3.500,-0.001 C5.432,-0.001 7.000,1.627 7.000,3.635 C7.000,5.644 3.500,8.999 3.500,8.999 ZM3.500,1.999 C2.671,1.999 2.000,2.670 2.000,3.499 C2.000,4.328 2.671,4.999 3.500,4.999 C4.328,4.999 5.000,4.328 5.000,3.499 C5.000,2.670 4.328,1.999 3.500,1.999 Z"
      />
    </svg>
  );
};
