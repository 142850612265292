import React from "react";
import { Container } from "../components/Container";
import { ExpertiseItem } from "../components/ExpertiseItem";
import { Icon } from "../components/Icon";

export const ExpertiseSection = ({ ...props }: ComponentProps) => {
  return (
    <section className="expertise" {...props}>
      <Container>
        <h2 className="expertise-title">What We Do</h2>

        <div className="expertise-items-list">
          <ExpertiseItem
            icon={<Icon name="explorer" />}
            title="Development of complex integrated projects"
            text="Highly competitive markets do not scare us. We successfully develop B2B and B2C projects, including SAAS, CRM, payment, partner, advertising systems."
          ></ExpertiseItem>
          <ExpertiseItem
            icon={<Icon name="promotion" />}
            title="Marketing analytics & promotion"
            text="We carefully study the markets, technologies, needs of the business and its customers, social and behavioral factors. And then we implement the most daring and effective marketing strategies."
          ></ExpertiseItem>
          <ExpertiseItem
            icon={<Icon name="star" />}
            title="Branding & market positioning"
            text="We wrap projects in the most attractive and relevant packaging with a deep understanding of the needs of end customers."
          ></ExpertiseItem>
          <ExpertiseItem
            icon={<Icon name="cloud" />}
            title="Cloud infrastructure management"
            text="We are a provider of our own cloud storage system for organizing cloud hosting projects."
          ></ExpertiseItem>
        </div>
      </Container>
    </section>
  );
};
