import React, { useEffect } from "react";
import { Header } from "./layout/Header";
import { PromoScreen } from "./layout/PromoScreen";
import { SolutionsSection } from "./layout/SolutionsSection";
import { ExpertiseSection } from "./layout/ExpertiseSection";
import { TechnologySection } from "./layout/TechnologySection";
import { ContactsSection } from "./layout/ContactsSection";
import { Footer } from "./layout/Footer";
import { VideoBackground } from "./components/VideoBackground";
import { MapSection } from "./layout/MapSection";

const fixVH = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

window.addEventListener("resize", fixVH);

export const App = () => {
  useEffect(() => {
    fixVH();
  }, []);

  return (
    <>
      <Header />
      <VideoBackground src="static/video/CyberCloth_2btr.mp4">
        <PromoScreen />
      </VideoBackground>
      <SolutionsSection data-section="solutions" />
      <ExpertiseSection data-section="expertise" />
      <TechnologySection data-section="technology" />
      <VideoBackground
        className="footer-background"
        src="static/video/CyberClothDark_2btr.mp4"
      >
        <ContactsSection data-section="contacts" />
        <Footer />
      </VideoBackground>
      <MapSection />
    </>
  );
};
