import React from "react";
import { ArrowIcon } from "./icons/ArrowIcon";
import { HandIcon } from "./icons/HandIcon";
import { ColoredHandIcon } from "./icons/ColoredHandIcon";
import { LocationIcon } from "./icons/LocationIcon";
import { HeartIcon } from "./icons/HeartIcon";
import { ConnectionIcon } from "./icons/ConnectionIcon";
import { GearIcon } from "./icons/GearIcon";
import { CommerceIcon } from "./icons/CommerceIcon";
import { HouseIcon } from "./icons/HouseIcon";
import { WalletIcon } from "./icons/WalletIcon";
import { ExplorerIcon } from "./icons/ExplorerIcon";
import { PromotionIcon } from "./icons/PromotionIcon";
import { StarIcon } from "./icons/StarIcon";
import { CloudIcon } from "./icons/CloudIcon";
import {
  AngularIcon,
  GitlabIcon,
  RailsIcon,
  ReactIcon,
  PythonIcon,
  NodeJsIcon,
  PostgreSqlIcon,
  UnityIcon,
} from "./icons/TechnologyIcons";
import { MarkerIcon } from "./icons/MarkerIcon";
import { CheckIcon } from "./icons/CheckIcon";
import { RemoveIcon } from "./icons/RemoveIcon";

const icons = new Map<string, (props: any) => JSX.Element>();

icons.set("arrow", ArrowIcon);
icons.set("hand", HandIcon);
icons.set("colored-hand", ColoredHandIcon);
icons.set("location", LocationIcon);
icons.set("wallet", WalletIcon);
icons.set("house", HouseIcon);
icons.set("commerce", CommerceIcon);
icons.set("gear", GearIcon);
icons.set("connection", ConnectionIcon);
icons.set("heart", HeartIcon);
icons.set("explorer", ExplorerIcon);
icons.set("promotion", PromotionIcon);
icons.set("star", StarIcon);
icons.set("cloud", CloudIcon);
icons.set("angular", AngularIcon);
icons.set("gitlab", GitlabIcon);
icons.set("rails", RailsIcon);
icons.set("react", ReactIcon);
icons.set("python", PythonIcon);
icons.set("node-js", NodeJsIcon);
icons.set("postgre-sql", PostgreSqlIcon);
icons.set("unity", UnityIcon);
icons.set("marker", MarkerIcon);
icons.set("check", CheckIcon);
icons.set("remove", RemoveIcon);

export const Icon = ({
  name,
  children,
  ...props
}: { name: string } & React.HTMLAttributes<HTMLElement>) => {
  const IconElement = icons.get(name);

  if (IconElement) {
    return <IconElement {...props} />;
  }

  return null;
};
