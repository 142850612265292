import React, { useState, useRef, useCallback, useEffect } from "react";
import { store } from "../store";
import { Container } from "../components/Container";
import { Logo } from "../components/Logo";
import { Nav, NavItem } from "../components/Nav";
import { ContactsList, ContactItem } from "../components/ContactsList";
import { Address } from "../components/Address";
import { Copyright } from "../components/Copyright";

const animate = (
  element: HTMLElement,
  type: 1 | -1 = 1,
  direction: 1 | -1 = 1,
) => {
  return element.animate(
    [
      { transform: `translate(-50%, -50%) translateY(${type * 3}px)` },
      { transform: `translate(-50%, -50%) translateY(0)` },
      {
        transform: `translate(-50%, -50%) translateY(0) rotate(${
          type * 45
        }deg)`,
      },
    ],
    {
      duration: 500,
      fill: "forwards",
      iterations: 1,
      direction: direction === 1 ? "normal" : "reverse",
    },
  );
};

const ToggleButton = ({
  active,
  ...props
}: ComponentProps & {
  active: boolean;
}) => {
  const [lastState, setLastState] = useState<boolean>(false);
  const line1Ref = useRef<HTMLDivElement>(null);
  const line2Ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (line1Ref.current && line2Ref.current && lastState !== active) {
      const line1 = line1Ref.current;
      const line2 = line2Ref.current;

      if (active) {
        animate(line1, 1, 1);
        animate(line2, -1, 1);
      } else {
        animate(line1, 1, -1);
        animate(line2, -1, -1);
      }
    }

    setLastState(active);
  }, [active, lastState]);

  return (
    <div className="toggle-button" {...props}>
      <div ref={line1Ref} className="line line-1"></div>
      <div ref={line2Ref} className="line line-2"></div>
    </div>
  );
};

export const Header = () => {
  const [active, setActive] = useState(false);
  const [lastState, setLastState] = useState<boolean>(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [floating, setFloating] = useState(window.pageYOffset > 0);
  const ref = useRef<HTMLDivElement>(null);
  const className = ["header"];

  const onClick = useCallback(() => {
    if (isAnimating === false) {
      setActive(!active);
    }
  }, [active, isAnimating]);

  useEffect(() => {
    const animate = async () => {
      if (lastState === active) {
        return;
      }

      setLastState(active);

      if (ref.current && isAnimating === false) {
        const height = active ? `100%` : floating ? `60px` : `88px`;
        setIsAnimating(true);

        const animation = ref.current.animate(
          [{ height: ref.current.clientHeight + "px" }, { height: height }],
          { duration: 300, fill: "forwards" },
        );

        await animation.finished;

        animation.cancel();

        setIsAnimating(false);
      }
    };

    animate();
  }, [active, isAnimating, lastState, floating]);

  const onScroll = useCallback(() => {
    if (window.pageYOffset > 0) {
      if (floating === false) {
        setFloating(true);
      }
    } else {
      if (floating === true) {
        setFloating(false);
      }
    }
  }, [floating]);

  const onNavClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setActive(false);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    onScroll();

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  if (active) {
    className.push("is-active");
  }

  if (isAnimating) {
    className.push("is-animating");
  }

  if (floating) {
    className.push("is-floating");
  }

  document.body.classList.toggle("no-scroll", active);

  return (
    <header ref={ref} className={className.join(" ")}>
      <Container>
        <Logo />
        <ToggleButton active={active} onClick={onClick} />
        <Nav>
          <NavItem link="#solutions" title="Solutions" onClick={onNavClick} />
          <NavItem link="#expertise" title="Expertise" onClick={onNavClick} />
          <NavItem link="#technology" title="Technology" onClick={onNavClick} />
          <NavItem link="#contacts" title="Contacts" onClick={onNavClick} />
        </Nav>
        <ContactsList>
          <ContactItem
            label="Send E-mail to"
            link={`mailto:${store.email}`}
            value={store.email}
          />
          <ContactItem
            label="Call the number"
            link={`tel:${store.phone}`}
            value={store.phone}
          />
        </ContactsList>
        <Address address={store.address} />
        <Copyright copyright={store.copyright} />
      </Container>
    </header>
  );
};
