import React from "react";
import { Button } from "./Button";

export const RetryButton = ({
  children,
  className,
  ...props
}: ComponentProps) => {
  return (
    <Button
      className={className ? `retry-button ${className}` : `retry-button`}
      {...props}
    >
      {children}
    </Button>
  );
};
