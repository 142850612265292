import React, { useCallback } from "react";
import { ConfirmButton } from "../components/ConfirmButton";
import { Icon } from "../components/Icon";
import { store } from "../store";
import { scrollToElement } from "../components/lib/scroll-to";

export const PromoScreen = () => {
  const scrollToHandler = useCallback(() => {
    scrollToElement(`[data-section="solutions"]`, 100);
  }, []);

  return (
    <section className="promo-screen">
      <div className="container">
        <h1 className="promo-header">
          Management Systems <span>& Automation</span> of Business Processes
        </h1>
        <p className="promo-text">
          We analyze, develop, brand, successfully launch & promote on the
          international market.
        </p>
        <div className="promo-controls">
          <ConfirmButton
            onClick={() => {
              scrollToElement(`[data-section="contacts"]`, 40);
            }}
          >
            Start project
          </ConfirmButton>
          <div className="promo-scroll" onClick={scrollToHandler}>
            <span>Scroll</span>
            <Icon name="arrow" />
          </div>
          <div className="say-hello">
            <div className="desktop-content">
              Say <Icon name="colored-hand" /> to{" "}
              <a
                href={`mailto:${store.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {store.email}
              </a>
            </div>
            <a
              className="mobile-content"
              href={store.email}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Email"
            >
              <Icon name="hand" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
