import React, { useCallback } from "react";
import Media from "js-media-query";
import { scrollToElement } from "./lib/scroll-to";

export const Nav = ({ children, ...rest }: ComponentProps) => {
  return (
    <nav className="nav" {...rest}>
      {children}
    </nav>
  );
};

export const NavItem = ({
  link,
  title,
  onClick,
  ...props
}: ComponentProps & { link: string; title: string }) => {
  const onClickHandler = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      }

      if (link.substr(0, 1) === "#") {
        const target = document.querySelector(
          `[data-section="${link.substr(1)}"]`,
        );

        if (target) {
          setTimeout(
            () => {
              scrollToElement(target, Media.max(767) ? 80 : 120);
            },
            Media.max(767) ? 300 : 0,
          );
        }
      }
    },
    [link, onClick],
  );

  return (
    <a href={link} className="nav-item" onClick={onClickHandler} {...props}>
      {title}
    </a>
  );
};
